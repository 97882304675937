//JOSH
.title-col {
  margin: 32px 0 20px 0;
  font-family: "Open Sans", sans-serif;
}

.subtitle-title-col {
  margin: 16px 0 16px 0;
  font-family: "Open Sans", sans-serif;
}

.title-name {
  color: #353957;
  font-size: 32px;
  font-weight: bold;
  line-height: 48px;
  font-family: "Open Sans", sans-serif;
}

.title-name-google-sans {
  color: #353957;
  font-size: 14px;
  height: 18px;
  font-family: "Open Sans", sans-serif;
}

.sub-title-name {
  color: #353957;
  font-size: 21px;
  font-weight: bold;
  line-height: 48px;
  font-family: "Open Sans", sans-serif;
}

.sub-title-name-google-sans {
  color: #353957;
  font-size: 14px;
  height: 18px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}

.sub-titile-2 {
  color: #9d9eae;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.add-more {
  color: #68b983;
  font-size: 13px;
  font-weight: bold;
  line-height: 48px;
  font-family: "Open Sans", sans-serif;
}

.btn-col {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.btn-add {
  height: 48px;
  width: 100% !important;
  border-radius: 24px !important;
  background-color: #ffba51 !important;
  color: #ffffff !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.btn-add-n100 {
  height: 48px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 24px !important;
  background-color: #ffba51 !important;
  color: #ffffff !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.btn-reset {
  height: 48px;
  width: 100% !important;
  border-radius: 24px !important;
  background-color: #ffffff !important;
  border: 1px solid #e21d35 !important;
  color: #e21d35 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.btn-reset-clear {
  height: 48px;
  width: 100% !important;
  border-radius: 24px !important;
  background-color: #ffffff !important;
  border: 1px solid #ffba51 !important;
  color: #ffba51 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.remove-modal-text {
  color: #353957;
  font-size: 24px;
  line-height: 30px;
  font-family: "Open Sans", sans-serif;
}

.border-line {
  height: 1px;
  background-color: #d2d2d2;
}

.filter-span {
  color: #a7a7c4;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;
}

.search-box {
  border-radius: 4px !important;
  border: 1px solid #dadbe4 !important;
  font-family: "Open Sans", sans-serif;
}

.active-span {
  background-color: #83cc39;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  padding: 8px;
  font-family: "Open Sans", sans-serif;
}

.nonactive-span {
  background-color: #e57f7f;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  padding: 8px;
  font-family: "Open Sans", sans-serif;
}

.pending-span {
  background-color: #ffc107;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  padding: 8px;
  font-family: "Open Sans", sans-serif;
}

.hypo-span {
  background-color: #4a90e2;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  padding: 8px;
  font-family: "Open Sans", sans-serif;
}

.select-box {
  border: 1px solid #dadbe4;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;
}

//form
.form-title {
  font-family: "Open Sans", sans-serif;
  color: #353957;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
}

.field-title {
  font-family: "Open Sans", sans-serif;
  color: #a7a7c4;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.field-input {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
  background-color: #ffffff !important;
  color: #353957 !important;
  border: 1px solid #dadbe4 !important;
  border-radius: 4px !important;
}

.field-input-disabled {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
  pointer-events: none !important;
  background-color: #f5f5f8 !important;
  color: #9d9eae !important;
  border: 1px solid #dadbe4 !important;
  border-radius: 4px !important;
}

.field-input-area {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
  background-color: #ffffff !important;
  color: #353957 !important;
  border: 1px solid #dadbe4 !important;
  border-radius: 4px !important;
  height: 100px !important;
}

.field-input-area-disabled {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
  pointer-events: none !important;
  background-color: #f5f5f8 !important;
  color: #9d9eae !important;
  border: 1px solid #dadbe4 !important;
  border-radius: 4px !important;
  height: 100px !important;
}

.field-input-area-long {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
  background-color: #ffffff !important;
  color: #353957 !important;
  border: 1px solid #dadbe4 !important;
  border-radius: 4px !important;
  height: 300px !important;
}

.field-input-area-long-disabled {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
  pointer-events: none !important;
  background-color: #f5f5f8 !important;
  color: #9d9eae !important;
  border: 1px solid #dadbe4 !important;
  border-radius: 4px !important;
  height: 300px !important;
}

.field-input-select {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #353957 !important;
  border: 1px solid #dadbe4 !important;
  border-radius: 4px !important;
  width: 100% !important;
  height: 100% !important;
}

.field-input-select-disabled {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
  pointer-events: none !important;
  background-color: #f5f5f8 !important;
  color: #9d9eae !important;
  border: 1px solid #dadbe4 !important;
  border-radius: 4px !important;
  width: 100% !important;
  height: 100% !important;
}

.upload-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-img-btn {
  font-family: "Open Sans", sans-serif;
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: 18px !important;
  text-align: center !important;
  color: #ffba51;
  background-color: #ffffff;
  border: 1px solid #ffba51 !important;
  border-radius: 24px !important;
  box-sizing: border-box;
  width: 50% !important;
  margin-left: 24%;
  cursor: pointer;
}

.upload-img-text {
  font-family: "Open Sans", sans-serif;
  color: #9093a4;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

.upload-img-border {
  width: 100%;
  height: 100%;
  border: 1px solid #cecece;
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 277px;
  max-width: 555px;
}

.change-password-text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #353957;
  cursor: pointer;
  text-decoration: underline;
}

//chat page
.chat-container {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  height: initial;
}

.chat-box {
  display: flex;
  flex-direction: row;
  flex-grow: 12;
  padding: 0 0 0 0 !important;
  height: 80vh;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 2px 20px 0 rgba(120, 120, 120, 0.06);
  border: 1px solid #e8e8e8;
}

.chat-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100% !important;
  padding: 0 0 0 0 !important;
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(120, 120, 120, 0.06);
  border: 1px solid #e8e8e8;
}

.chat-list-content {
  padding: 0 5px !important;
  display: flex;
  align-content: center;
  align-items: center;
  height: 10%;
  background-color: #ffffff;
}

.chat-room {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 0 !important;
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(120, 120, 120, 0.06);
  border: 1px solid #e8e8e8;
  color: #353957;
  font-size: 32px;
  font-weight: bold;
  line-height: 48px;
  font-family: "Open Sans", sans-serif;
}

//chat span component
.chat-span {
  padding-top: 10%;
  border-radius: 10px;
}

.chat-span-title {
  font-size: 24px;
  color: #353957;
  font-weight: bold;
  line-height: 30px;
  font-family: "Open Sans", sans-serif;
}

.chat-span-col {
  align-content: center;
  text-align: center;
  padding: 25px 70px !important;
}

.chat-cancel-btn {
  height: 50px !important;
  width: 100% !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  color: #ffba51 !important;
  border: 1px solid #ffba51 !important;
  border-radius: 24px !important;
  background-color: #ffffff !important;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.chat-add-btn {
  height: 100% !important;
  width: 100% !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  color: #ffffff !important;
  border-radius: 24px !important;
  background-color: #ffba51 !important;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.chat-close-btn {
  height: 100% !important;
  width: 100% !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  background-color: #ffffff !important;
  border-radius: 24px !important;
  color: #ffba51 !important;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

//chat-room component
.chat-room-container {
  display: Flex !important;
  flex-wrap: wrap !important;
  padding: 0 !important;
  flex-direction: column !important;
}

.chat-room-header-container {
  display: Flex !important;
  flex-grow: 1 !important;
  padding: 0 !important;
  flex-basis: 10% !important;
}

.chat-room-message-container {
  display: Flex !important;
  flex-grow: 1 !important;
  padding: 0 !important;
  flex-basis: 80% !important;
}

.chat-room-input-container {
  display: Flex !important;
  flex-grow: 1 !important;
  padding: 0 !important;
  flex-basis: 10% !important;
}

//chat-tab component
.chat-tab {
  height: 90%;
  width: 100%;
  color: #353957;
  font-size: 20px;
  font-style: bold;
  line-height: 48px;
  overflow-y: scroll;
  font-family: "Open Sans", sans-serif;
}

.chat-tab-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  background-color: #ffffff;
  padding: 0 !important;
  margin: 2px 0 !important;
  flex-direction: column !important;
  align-items: center !important;
}

.chat-tab-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  background-color: #ffffff;
  padding: 0 !important;
  margin: 2px 0 !important;
  flex-direction: column !important;
  align-items: center !important;
}

.chat-tab-container-active {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  background-color: #f4f7fa;
  padding: 0 !important;
  margin: 2px 0 !important;
  flex-direction: column !important;
  align-items: center !important;
}

.chat-tab-container:hover {
  background-color: #f4f7fa;
  box-shadow: 0 2px 20px 0 rgba(120, 120, 120, 0.06);
}

.chat-tab-container-content {
  padding: 0 !important;
  width: 100% !important;
  height: 50% !important;
  display: flex !important;
}

.chat-tab-name-container {
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chat-tab-name {
  color: #353957;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  padding: 10px 10px 0 10px;
  font-family: "Open Sans", sans-serif;
}

.chat-tab-message-container {
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  text-align: justify;
  overflow: hidden;
}

.chat-tab-message {
  color: #b3b5c5;
  font-size: 12px;
  margin: 0;
  padding: 0px 10px 0px 10px;
  line-height: 25px;
  word-spacing: normal;
  text-align: left;
  text-overflow: ellipsis;
  font-family: "Open Sans", sans-serif;
}

.chat-tab-time-container {
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}

.chat-tab-time {
  color: #353957;
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  padding: 10px 10px 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
}

//chat-header component
.chat-header-container {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: #4a4a4a;
  border-bottom: 1px solid #e8e8e8;
}

.chat-header-title {
  color: #353957;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
}

//chat-message component
.chat-message-container {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

.chat-other-message {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
}

.chat-other-message-box {
  padding: 10px;
  margin: 5px 10px 5px 0;
  max-width: 50%;
  background-color: #f1f1f1;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.chat-other-message-name {
  color: #b469aa;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  font-family: "Open Sans", sans-serif;
}

.chat-other-message-text {
  margin: 0 !important;
  padding: 0 !important;
  color: #353957;
  opacity: 90%;
  font-size: 12px;
  line-height: 16px;
  text-align: justify;
  font-family: "Open Sans", sans-serif;
}

.chat-other-time {
  color: #b3b5c5;
  font-size: 12px;
  line-height: 16px;
  align-self: flex-end;
  margin-bottom: 5px;
  font-family: "Open Sans", sans-serif;
}

.chat-message {
  display: flex;
  flex-direction: row;
  padding-right: 0;
  padding-left: auto;
  justify-content: flex-end;
  padding: 0 !important;
}

.chat-message-box {
  padding: 10px;
  margin: 5px 0 5px 10px;
  max-width: 50%;
  background-color: #b469aa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.chat-message-name {
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  font-family: "Open Sans", sans-serif;
}

.chat-message-text {
  margin: 0 !important;
  padding: 0 !important;
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  text-align: justify;
  font-family: "Open Sans", sans-serif;
}

.chat-message-pdf-container {
  display: flex;
  flex-direction: row;
}

.chat-pdf-img-container {
  margin: 0 10px 0 0;
}

.chat-pdf-name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
  margin: 0 10px 0 0;
}

.chat-pdf-name {
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
}

.chat-other-pdf-name {
  color: #353957;
  font-size: 16px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
}

.chat-pdf-size {
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  font-family: "Open Sans", sans-serif;
}

.chat-other-pdf-size {
  color: #9093a4;
  font-size: 12px;
  line-height: 16px;
  font-family: "Open Sans", sans-serif;
}

.chat-img {
  width: 100%;
  height: auto;
}

.chat-time {
  color: #b3b5c5;
  font-size: 12px;
  line-height: 16px;
  align-self: flex-end;
  margin-bottom: 5px;
  font-family: "Open Sans", sans-serif;
}

//chat-input component
.chat-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: space-around;
  background-color: #f8f8f8;
  text-align: center;
  padding: 0 !important;
}

.chat-input-component-container {
  display: flex;
  justify-content: center;
  justify-self: center;
  justify-items: center;
  padding: 0 !important;
}

.chat-input-button {
  background-color: #686c87;
  height: 40px;
  width: 40px;
  border-radius: 6px;
  display: inline-block;
  cursor: pointer;
}

.chat-input-typing {
  padding: 10px;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  border: 1px solid #dadbe4 !important;
  height: 40px !important;
  width: 100%;
  overflow-y: scroll !important;
  font-size: 14px;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;
}

//report list component
.no-data-col {
  align-content: center;
  text-align: center;
  padding: 50px;
}

.filterMargin {
  margin-top: 5px;
}

.banner {
  margin-left: 125px;
}

@media only screen and (max-width: 1200px) {
  .banner {
    margin-left: 75px;
  }
}

@media only screen and (max-width: 1024px) {
  .banner {
    margin-left: 75px;
  }
}

@media only screen and (max-width: 991px) {
  .banner {
    margin-left: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner {
    margin-left: unset;
  }
}
